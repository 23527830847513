<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="处方类型">
                <el-select v-model="searchForm.type" placeholder="请选择" size="small" style="margin-right: 10px; width: 120px" @change="Search">
                  <el-option v-for="item in categoryData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入处方名称..." @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="prescriptionName" label="处方名称"> </el-table-column>
          <el-table-column prop="type" label="类型" align="center" sortable>
            <template slot-scope="scope">
              <p v-if="scope.row.type==0">方剂处方</p>
              <p v-if="scope.row.type==1">成药处方</p>
              <p v-if="scope.row.type==2">输液处方</p>
            </template>
           </el-table-column>
          <el-table-column prop="userName" label="所属医生" align="center"> </el-table-column>
          <el-table-column prop="addTime" label="上传时间" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.addTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="infoBtn(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      oranizationId: null,
      UserId: null,
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      DialogTitle: '添加银行卡',
      bankCardForm: {
        userName: '',
        cardNo: '',
        bankName: '',
        isDefault: false,
        oranizationId: null,
        operationUserId: null,
      },
      searchForm: {
        type: '0',
        keyWord: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      categoryData: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '1',
          label: '方剂处方',
        },
        {
          value: '2',
          label: '成药处方',
        },
        {
          value: '3',
          label: '输液处方',
        },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    infoBtn(row) {
      let path = ''
      if (row.type == 0) {
        path = './AddPillDrugPrescription'
      } else if (row.type == 1) {
        path = './AddProductDrugPrescription'
      } else if (row.type == 2) {
        path = './AddInfusionDrugPrescription'
      }
      this.$router.push({
        path:path,
        query:{
          id:row.id
        }
      })
    },
    Search(){
      this.pageIndex=1
      this.getList() 
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData=[]
      _this.OutpatientDomain.OrgCommonPrescription(this.searchForm.type,this.searchForm.keyWord, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
